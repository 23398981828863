/* eslint-disable react/no-danger */
// @flow
import React from 'react';
import { Container, Box } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import cx from 'classnames';
import styles from './Conditions.module.scss';
import iPhoneExchangeImg from '../../images/iphone-exchange.jpeg';

const requiredConditionsItems = [
  <span>
    18歳以上であること
    <br />
    ※また、未成年の方は親権者の同意を取得の上お申込みください。
  </span>,
  <span>携帯電話番号(日本で発行された番号であること）</span>,
  <span>メールアドレス</span>,
  <span>運転免許証またはマイナンバーカード</span>,
  <span>
    日本に在住であること（日本在住でない場合、有効な日本の住所を持っていること）
  </span>,
];

const costsItems = [
  { name: '分割手数料', value: '0%*' },
  { name: '申し込み手数料', value: 'なし' },
  { name: '月額利用料', value: 'なし' },
  { name: '年間利用料', value: 'なし' },
  {
    name: '遅延手数料',
    value: `153円
  <small>*支払いが遅延した際にかかる費用</small>
  `,
  },
  {
    name: '支払い手数料',
    value: `お支払い方法により異なります。
      <small>・コンビニ： 税込390円（1回あたり最大）
      <br>
      ・口座振替： 無料
      <br>
      ・銀行振込： 金融機関により振込手数料が異なる。</small>`,
  },
];

const costsItemsNotes = ['* 口座振替・銀行振込のみ0％。'];
const exchangeNotes = ['* 口座振替・銀行振込のみ0％。'];

const Conditions = () => {
  return (
    <div className={styles.container}>
      <div className={styles.requirements}>
        <Container maxWidth="md" className={styles.requirementsWrapper}>
          <h3>
            お申し込み条件・
            <br />
            必要なもの
          </h3>
          <div className={styles.content}>
            <p className={cx(styles.description, styles.highlight)}>
              「ペイディあと払いプランApple専用」へのお申し込みに必要なものは以下の通りです。
            </p>
            <ul>
              {requiredConditionsItems.map((requirement, index) => (
                <li
                  key={`apple-requirement-${index}`}
                  className={styles.requirementItem}
                >
                  <span>・</span>
                  {requirement}
                </li>
              ))}
            </ul>
            <strong className={styles.highLight}>
              直営店のApple
              Storeで購入する際に、身分証明書などによる本人確認をさせていただきます。
            </strong>
            <br />
            <br />
            <p className={styles.description}>
              「ペイディあと払いプランApple専用」ご利用規約は
              <OutboundLink
                className="link"
                href="https://terms.paidy.com/hokatsu-apple/"
                rel="noopener noreferrer"
                target="_blank"
              >
                こちら
              </OutboundLink>
              をご確認ください。
            </p>
          </div>
        </Container>
      </div>
      <div className={styles.costs}>
        <Container className={styles.costsWrapper} maxWidth="md">
          <h3>費用は最小限。</h3>
          <div className={styles.content}>
            <p>
              「ペイディあと払いプランApple専用」は、分割手数料が0%*であるだけでなく、初期費用や月額費用がかかりません。
            </p>
          </div>
          <ul className={styles.costsList}>
            {costsItems.map(({ name, value }) => (
              <li key={name}>
                <h4>{name}</h4>
                <span
                  dangerouslySetInnerHTML={{
                    __html: value,
                  }}
                />
              </li>
            ))}
          </ul>
          <ul className={styles.notesList}>
            {costsItemsNotes.map((note) => (
              <li key={note}>{note}</li>
            ))}
          </ul>
        </Container>
      </div>
      <div className={styles.exchange}>
        <div className={styles.exchangeWrapper}>
          <img
            className={styles.image}
            src={iPhoneExchangeImg}
            alt="iPhone Exchange"
            loading="lazy"
          />
          <div className={styles.information}>
            <h3>
              お気に入りのデバイスを、
              <br />
              次の気に入るデバイスに。
            </h3>
            <p>
              Appleで新しいデバイスを購入する際に対象となるデバイスをApple Trade
              Inを利用して下取りに出すと、新しいデバイスの購入価格から下取り額分が割引になります。
            </p>
            <Box
              className={styles.detailsLink}
              component={OutboundLink}
              href="https://www.apple.com/jp/trade-in/?cid=aos-jp-aff-bp-paidy-web"
              rel="noopener noreferrer"
              target="_blank"
            >
              詳しくはこちら
              <img
                src={require('../../images/icon-chevron-right-pink.svg')}
                alt="Chevron icon"
              />
            </Box>
            <hr />
            <ul className={styles.notesList}>
              {exchangeNotes.map((note) => (
                <li key={note}>{note}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conditions;
