/* eslint-disable react/no-danger */
// @flow
import React from 'react';
import { Container, Box } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import styles from './Features.module.scss';
import iPhoneImg from '../../images/iphone16.png';

const featureItems = [
  {
    title: '分割手数料無料0％*で最大36回払い',
    description: (
      <span>
        製品に応じて最大36回でお支払い可能。分割回数に関わらず、分割手数料は一切かかりません。
        <br />
        <OutboundLink
          href="https://www.apple.com/jp/shop/browse/financing?cid=aos-jp-aff-bp-paidy-web"
          rel="noopener noreferrer"
          target="_blank"
        >
          Appleで分割手数料0％の製品をチェック。
        </OutboundLink>
      </span>
    ),
  },
  {
    title: 'Apple専用の特別なご利用額をご用意',
    description:
      '通常のペイディのご利用可能額とは別に、「ペイディあと払いプランApple専用」の特別なご利用額をご用意。Apple専用のご利用可能額がペイディアプリで確認できます。',
  },
];

const notes = ['* 口座振替・銀行振込のみ0％。'];

const Features = () => {
  return (
    <Box className={styles.wrapper}>
      <Container className={styles.container} maxWidth="md">
        <div className={styles.image}>
          <img
            src={iPhoneImg}
            className={styles.imgSp}
            alt="iPhone 16"
            loading="lazy"
          />
          <img
            src={iPhoneImg}
            className={styles.imgPc}
            alt="iPhone 16"
            loading="lazy"
          />
        </div>
        <div className={styles.listWrapper}>
          <ul className={styles.featuresList}>
            {featureItems.map(({ title, description }) => (
              <li key={title}>
                <h3>{title}</h3>
                <p>{description}</p>
              </li>
            ))}
          </ul>
          <hr />
          <ul className={styles.notesList}>
            {notes.map((note) => (
              <li key={note}>{note}</li>
            ))}
          </ul>
        </div>
      </Container>
    </Box>
  );
};

export default Features;
