import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Img2 from 'src/images/apple-benefit-2.svg';
import Img3 from 'src/images/apple-benefit-3.svg';
import PinkLeftArrowIcon from 'src/images/pink-left-arrow.svg';
import styles from './Benefits.module.scss';

const DATA = [
  {
    img: Img2,
    title: (
      <>
        お好きなキャリアを
        <br />
        自由に選べる。
      </>
    ),
    text:
      'SIMフリーのiPhoneなら、キャリアを自由に選ぶことができます。今のキャリアをそのまま利用することはもちろん、格安SIMやキャンペーンを組み合わせてお得に利用するなど、あなたにぴったりのプランをデザインできます。',
  },
  {
    img: Img3,
    title: <>Appleならサポートも万全。</>,
    text:
      '新しいiPhoneを使い方にあわせてすばやく簡単にセットアップできます。Apple Storeにご来店いただくと、データ、連絡先、写真などの移行をサポート。Face IDやTouch IDなどのセキュリティ機能、プライバシー機能の設定方法もご案内します。',
  },
];

export default function Benefits({ data = DATA }) {
  return (
    <section id="benefits" className={styles.section}>
      <h1 className={styles.title}>iPhoneの購入もAppleがベスト。</h1>
      <div className={styles.container}>
        {data.map((item) => (
          <div key={item.key} className={styles.item}>
            <img
              src={item.img}
              alt={`item-${item.key}`}
              height={160}
              width={160}
            />
            <h2 className={styles.title}>{item.title}</h2>
            <p className={styles.text}>{item.text}</p>
          </div>
        ))}
      </div>
      <OutboundLink
        className={styles.appleLink}
        href="https://www.apple.com/jp/shop/buy-iphone/iphone-16?cid=aos-jp-aff-bp-paidy-web-benefits-event "
        rel="noopener noreferrer"
        target="_blank"
      >
        詳しくはこちら
        <img
          alt="icon"
          src={PinkLeftArrowIcon}
          className={styles.pinkLeftArrowIcon}
        />
      </OutboundLink>
    </section>
  );
}
